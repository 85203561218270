import React from "react";
import "./tech-demo-calendar.scss";

export const TechDemoCalendar: React.FC<{}> = () => {
  return (
    <div id="technical-demo" className="mt-20 pt-20">
      <div className="row flex justify-center">
        <div className="col-12 col-lg-6 text-left">
          <h2>Get a Free Technical Demo</h2>
          <h3>
            See what ZeroOps application delivery looks like for your team.
          </h3>
          <div className="text-section">
            <p className="mb-4">What to expect when completing our form:</p>
            <ul>
              <li>You’ll book a time with our team straight away</li>
              <li>We’ll follow up and ask for any relevant details</li>
              <li>
                Get ready for a personalized demo tailored to your business and
                use case
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-lg-6 text-center flex justify-center">
          <img
            alt="technical-demo-"
            src="https://images.prismic.io/amazeeio/ef0e6449-4032-422e-a33a-0ea06072796e_Get-a-Technical-Demo.png?auto=compress%2Cformat"
          />
        </div>
      </div>
      <section>
        <iframe
          title={"hubspot"}
          style={{
            width: "100%",
            height: "800px",
          }}
          src={
            "https://go.amazee.io/meetings/martin-schloegl/live-demo?embed=true"
          }
        />
      </section>
    </div>
  );
};
